.proj_parent_block{
    padding: 180px 0px;
}
.proj_title_main{
    color: #101828;
    font-family: 'ExtraBold';
    font-size: 52px;
    line-height: 128%; /* 53.76px */
}
.proj_tagline{
    color: #101828;
    font-family: 'SemiBold';
    font-size: 24px;
    line-height: 140%; /* 53.76px */
}
.main_hero_proj{
    max-width: 100%;
    height: auto;
    margin: 32px 0px 52px 0px;
}