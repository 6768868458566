.aboutDiv{
    padding: 110px 0px 0px 0px;
    background-color: #000;
}
.spaceTopA{
    padding-top: 40px;
}
.aboutMainHead{
    font-family: 'SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #00FFFF;
    margin-top: -32px;
}
.animateBoy{
    margin-top: 100px;
}
.aboutHeader{
    max-width: 100%;
    height: auto;
}
.styleLine{
    width: 100%;
    background-color: #fee27256;
    margin-top: 60px;
}
.secondFoldA{
    margin-top: 60px;
}
.leftHeadA{
    font-family: 'Black';
    font-size: 46px;
    text-align: left;
    letter-spacing: 0.07em;
    color: #FFDC09;
}
.colorHighlight{
    color: #fff;
}
.paraRightA{
    font-family: 'Medium';
    font-size: 18px;
    text-align: left;
    color: #fff;
    line-height: 160%;
}
.caricatureStyle{
    max-width: 100%;
    height: auto;
}
.philosophyDiv{
    padding: 100px 0px;
}
.philosophyHead{
    font-family: 'Bold';
    font-size: 24px;
    text-align: left;
    color: #101010;
    margin-bottom: 0px;
}
.philosophyTxt{
    font-family: 'Medium';
    font-size: 18px;
    text-align: left;
    color: #4F4F4F;
    margin-top: 0px;
    line-height: 22px;
}
.cardsPhilosophy{
    padding-top: 50px;
}
.philosophyImage{
    padding-bottom: 20px;
}
.cardMainCreative{
    text-align: left;
}
.customHeight{
    padding-bottom: 26px;
    padding-top: 20px;
}
/* .customHeightLast{
    padding-bottom: 30px;
} */
.customImgHeight{
    max-width: 100%;
}
.aboutParaOneDiv{
    padding: 80px 0px 80px 0px;
    background-color: #fafafa;
}
.aboutParaTwoDiv{
    padding: 80px 0px 40px 0px;
    background-color: #ffffff;
}
.paraOneHead{
    font-family: 'SemiBold';
    font-size: 32px;
    text-align: left;
    color: #101010;
    margin-top: 0px;
    line-height: 120%;
    padding-top: 120px;
}
.paraTwoHead{
    font-family: 'SemiBold';
    font-size: 32px;
    text-align: left;
    color: #101010;
    margin-top: 0px;
    line-height: 120%;
    padding-top: 160px;
}
.paraTwoHeadLast{
    font-family: 'Bold';
    font-size: 40px;
    text-align: left;
    color: #101010;
    margin-top: 0px;
    line-height: 120%;
    padding-top: 160px;
}
.nameTeam{
    font-family: 'Bold';
    font-size: 24px;
    text-align: left;
    color: #101010;
    margin-top: 8px;
    margin-bottom: 0px;
}
.designation{
    font-family: 'Bold';
    font-size: 16px;
    text-align: left;
    color: #585858;
    margin-top: 0px;
}
.joinHead{
    font-family: 'Medium';
    font-size: 30px;
    text-align: center;
    color: #101010;
    line-height: 120%;
    margin-top: 70px;
    margin-bottom: 12px;
}
.styleCustom{
    width: 100%;
    border: 1px solid #e4e4e4 !important;
    background-color: #fafafa;
    margin: 60px auto;
}
.alignLeftanimate{
    text-align: left;
}
.teamDiv{
    padding: 100px 0px;
    background-color: #fafafa;
}
.spacePadding{
    padding: 100px 0px;
}
.teamCardStyle{
    max-width: 100%;
    height: auto;
    background-color: #fff;
}
.teamCardStyle:hover{
    transition: .4s all ease-in-out;
    background-color: #FFD028;
    cursor: pointer;
}
.leftTeamCard{
    text-align: left;
}
.spaceBottomTeam{
    padding-bottom: 20px;
}
.pBTeam{
    padding-bottom: 32px;
}
.cardJoin{
    position: relative;
    text-align: center;
}
.teamJoin{
    font-family: 'ExtraBold';
    font-size: 20px;
    text-align: center;
    color: #3669F6;
    line-height: 120%;
    margin-bottom: 0px;
}
.teamJoin:hover{
    transition: .5s all ease;
    color: #000000;
}
.spaceCtaTop{
    margin-top: 30px;
    text-align: left;
}
.aboutAdjustAnimate{
    height: 350px;
}
.Dview{
    display: block;
}
.Mview{
    display: none;
}
@media only screen and (max-width: 600px) {
    .aboutAdjustAnimate{
        height: 220px !important;
    }
    .Mview{
        display: block !important;
    }
    p.aboutMainHead {
        font-size: 18px !important;
        margin-top: -15px !important;
    }
    .leftHeadA {
        font-size: 36px !important;
    }
    .paraRightA {
        font-size: 18px !important;
    }
    .Dview{
        display: none !important;
    }
    .caricatureStyle{
        margin-top: -18px;
    }
    .styleLine, .secondFoldA {
        margin-top: 46px;
    }
    .philosophyDiv, .spacePadding, .teamDiv, .ctaAboutLast{
        padding: 80px 0px;
    }
    .cardMainCreative {
        text-align: center;
    }
    .p-Bottom{
        margin-bottom: 30px;
    }
    .centerMview, .philosophyHead, .philosophyTxt,.leftTeamCard, .nameTeam, .designation, .joinHead, .cardJoin, .spaceCtaTop{
        text-align: center;
    }
    .leftTeamCard{
        padding-bottom: 20px;
    }
    .paraOneHead, .paraTwoHead{
        padding-top: 0px;
        font-size: 30px;
        margin-bottom: 0px;
    }
    .mStyleAnimateS{
        padding-bottom: 30px;
    }
    .joinHead{
        margin-top: 20px;
    }
    .pBTeam{
        padding-bottom: 0px;
    }
    .paraTwoHeadLast{
        padding-top: 30px;
        text-align: center;
        font-size: 30px;
    }
    .spaceCtaTop{
        margin-top: 32px;
    }
    .ctaAboutLast{
        padding: 0px 0px 80px 0px;
    }
    .alignCentertxt{
        text-align: center;
    }
}