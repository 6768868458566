.connectMain{
    padding: 60px 0px 30px 0px;
}
.heightAdjustC{
    max-width: 100%;
    height: 650px;
}
.connectMainHead{
    font-family: 'Black';
    font-style: normal;
    font-size: 42px;
    color: #101010;
    text-align: left;
    text-transform: uppercase;
    margin-top: 25%;
}
.highlightConnect{
    color: #3F81FF;
}

.connectSub{
    font-family: 'Medium';
    font-style: normal;
    font-size: 22px;
    color: #3b3b3b;
    text-align: left;
    margin-top: 10px;
}
.connectLink{
    font-family: 'SemiBold';
    font-style: normal;
    font-size: 20px;
    color: #101010;
    text-align: left;
    border-radius: 6px;
    border: 2px solid #101010;
    padding: 12px 40px;
}
.connectLink:hover{
    color: #ffffff;
    transition: ease-in .3s all;
    background-color: #101010;
}
.ConnectStyle{
    text-align: left;
    margin-top: 40px;
}
.spaceTel{
    text-align: left;
    margin-top: 35px;
}
.addressConnect{
    font-family: 'Medium';
    font-style: normal;
    font-size: 20px;
    color: #3b3b3b;
    text-align: left;
    margin-top: 30px;
}
.mView{
    display: none;
}
.dView{
    display: block;
}
@media only screen and (max-width: 600px) {
    .mView{
        display: block !important;
    }
    .dView{
        display: none !important;
    }
    .heightAdjustC{
        height: auto !important;
    }
    .connectMainHead{
        margin-top: 30px;
        font-size: 32px;
        text-align: center;
    }
    .connectMain{
        padding: 80px 0px 30px 0px;
    }
    .connectSub{
        text-align: center;
    }
    .ConnectStyle, .spaceTel, .addressConnect{
        text-align: center;
    }
}