body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face{
  font-family: 'Light';
  src:  url('./Assets/fonts/Gilroy/GilroyLight.ttf') ;
}
@font-face{
  font-family: 'Regular';
  src:  url('./Assets/fonts/Gilroy/GilroyRegular.ttf') ;
}
@font-face{
  font-family: 'Medium';
  src:  url('./Assets/fonts/Gilroy/GilroyMedium.ttf') ;
}
@font-face{
  font-family: 'SemiBold';
  src:  url('./Assets/fonts/Gilroy/GilroySemiBold.ttf') ;
}
@font-face{
  font-family: 'Bold';
  src:  url('./Assets/fonts/Gilroy/GilroyBold.ttf') ;
}
@font-face{
  font-family: 'ExtraBold';
  src:  url('./Assets/fonts/Gilroy/GilroyExtraBold.ttf') ;
}
@font-face{
  font-family: 'Black';
  src:  url('./Assets/fonts/Gilroy/GilroyBlack.ttf') ;
}
@font-face{
  font-family: 'Heavy';
  src:  url('./Assets/fonts/Gilroy/GilroyHeavy.ttf') ;
}
a{
  text-decoration: none !important;
}