html, body {
    max-width: 100vw !important;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
.firstFold{
    padding: 160px 0px 50px 0px;
}
.bg-light{
    background-color: #fff !important;
}
.MainHeadSmall{
    font-family: 'SemiBold';
    font-style: normal;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #101010;
    margin-top: 20px;
    margin-bottom: 30px;
}
.mainHeadBig{
    font-family: 'Black';
    font-style: normal;
    font-size: 90px;
    text-align: center;
    letter-spacing: normal;
    color: #101010;
    -webkit-animation: titleMove 2.4s ease-in-out infinite;
    -moz-animation: titleMove 2.4s ease-in-out infinite;
    -o-animation: titleMove 2.4s ease-in-out infinite;
    animation: titleMove 2.4s ease-in-out infinite;
}
@keyframes titleMove {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}
.outlineStroke{
    color: black;
	-webkit-text-stroke: 1px black;
	-webkit-text-fill-color: white;
}
.MainHeadLower{
    font-family: 'Medium';
    font-style: normal;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #3b3b3b;
    margin-top: 20px;
}
.whiteBtnBg{
    padding: 14px 30px;
    font-family: 'Bold';
    font-size: 16px;
    color: #101010;
    text-align: center; 
    border: 2px solid #101010;
    border-radius: 6px;
}
.whiteBtnBg:hover{
    background-color: #101010;
    color: #fff;
    transition: ease-in .3s all;
}
.whiteBtn{
    padding: 14px 30px;
    font-family: 'Bold';
    font-size: 14px;
    color: #101010;
    text-align: center; 
    border: 1px solid #101010;
    border-radius: 6px;
}
.whiteBtn:hover{
    background-color: #101010;
    color: #fff;
    transition: ease-in .3s all;
}
.blackBtn{
    padding: 12px 30px;
    font-family: 'Bold';
    font-size: 14px;
    color: #ffffff;
    text-align: center; 
    border: 2px solid #ffffff;
    border-radius: 6px;
}
.blackBtn:hover{
    background-color: #ffffff;
    font-family: 'Bold';
    font-size: 14px;
    color: rgb(0, 0, 0);
    transition: ease-in .3s all;
    text-align: center; 
    border: 2px solid #ffffff;
    border-radius: 6px;
}
.secondFoldBg{
    background-color: #0b1212;
    padding-bottom: 20px;
}
.img-fluid{
    height: 74px;
}
.papers-slideshow.slideleft {
    background-image: url('../Assets/home/paperLayer.png');
}
.papers-slideshow {
    background-size: 1500px;
    background-repeat: repeat-x;
    width: 100%;
    height: 74px;
}
.papers-slideshow.slideleft {
    animation: slideright 200s infinite linear;
    -webkit-animation: slideright 200s infinite linear;
    background-image: url(../Assets/home/paperLayer.png);
    background-repeat: repeat;
}
@keyframes slideright {
    0%{
        background-position: 0px;
    }
    100%{
        background-position: -10000px;
    }
}
.teamImageStyle{
    height: 250px;
    margin-left: 3%;
    max-width: 100%;
}
.avatarsImageBox{
    text-align: left;
}
.paper-slider{
    margin: auto;
    overflow: hidden;
    position: relative;
    margin-top: -69px;
}

.paper-slider:before {
    left: 0;
    top: 0;
}
:not(svg) {
    transform-origin: 0 0;
}
.paper-slider:after, .paper-slider:before {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}
.paper-slider .paper-slide-track {
    -webkit-animation: scroll 100s linear infinite;
    animation: scroll 100s linear infinite;
    display: flex;
    width: 8000px;
}
.paper-slider:after {
    right: 0;
    top: 0;
    transform: rotate(180deg);
}
@keyframes scroll{
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-5000px);
    }
}
.mainHead{
    font-family: 'Black';
    font-style: normal;
    font-size: 34px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.topBottom{
    padding: 100px 0px;
}
.mainBodyPara{
    font-family: 'Medium';
    font-size: 20px;
    line-height: 1.5em;
    color: #fff;
    text-align: left;
    margin-top: 15px;
}
.displayBelow{
    display: grid;
}
.spaceTop{
    margin-top: 40px;
}
.secondFold{
    z-index: 1212;
}
.btnDark{
    background-color: #101010;
    padding: 20px 42px;
    font-family: 'Semibold';
    font-weight: normal;
    font-size: 18px;
    line-height: 1.4em;
    color: #ffffff;
    border-radius: 6px;
    text-align: center;
}
.btnDark:hover{
    color: #FFE948;
    text-decoration: none;
    transition: 1s all ease;
}
.btnSpaceTop{
    margin-top: 44px;
    margin-bottom: 40px;
}
.styleImage{
    height: 380px;
}
.alignLeft{
    text-align: left;
    margin: 40px 0px 0px 0px;
}
.alignLeftSecond{
    text-align: left;
}
.outlineBtn{
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 18px 32px;
    font-family: 'SemiBold';
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.outlineBtn:hover{
    color: #FFE948;
    animation: triangleg 1.5s linear infinite;
        background: linear-gradient(
            135deg, #3F81FF 0.25em, transparent 0.25em) -0.5em 0, linear-gradient(
            225deg, #3F81FF 0.25em, transparent 0.25em) -0.5em 0, linear-gradient(
            315deg, #3F81FF 0.25em, transparent 0.25em) 0 0, linear-gradient(
            45deg, #3F81FF 0.25em, transparent 0.25em) 0 0;
        background-size: 0.75em 0.6em;
        color: white !important;
        background-color: transparent !important;
        border-color: #3F81FF !important;
}
.arrowSpacing{
    height: 18px;
    margin-left: 12px;
}

@keyframes triangleg {
    100% {
        background-position: 1em 0, 1em 0, -0.75em 0 , -0.75em 0;
    }
}
a:hover{
text-decoration: none;
}

.thirdFold{
    padding: 120px 0px;
}
.styleWwd{
    height: 480px;
    max-width: 100%;
}
.mainHeadLight{
    font-family: 'Black';
    font-style: normal;
    font-size: 34px;
    letter-spacing: 0.01em;
    color: #101010;
    text-align: left;
    text-transform: uppercase;
}
.styleWwd3{
    height: 400px;
    margin-top: 100px;
}
.styleWwd4{
    height: 450px;
    margin-top: 60px;
}
.styleWwd5{
    height: 450px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
}
.styleWwd6{
    height: 450px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
}
.styleWwd7{
    height: 330px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}
.subheadSecond{
    font-family: 'Medium';
    font-style: normal;
    font-size: 20px;
    line-height: 140%;
    color: #4F4F4F;    
    text-align: left;
}
.bold{
    font-family: 'SemiBold';
    color: #101010;
}
.spaceBottomSecond{
    margin-bottom: 35px;
}
.styleNavTab{
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: 'SemiBold';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #3b3b3b;
    padding: 16px 0px;
}
.styleNavTab:hover{
    color: #fff;
    background-color: #101010 !important;
    transform: perspective(1px);
    transition: all .9s cubic-bezier(.5,.2,0,1);
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #101010;
    transition: .6s all ease-in-out;
}
.styleRowBottom{
    margin-bottom: 20px;
}
.spaceTopSecond{
    margin-top: 20px;
}

.fourthFold{
    background-color: #101010;
    padding: 120px 0px;
    background-image: url('../Assets/blackBg.svg');
    background-size: cover;
}
.subPara{
    font-family:'Medium';
    font-size: 20px;
    line-height: 1.5;
    color: #ffffff;
    text-align: left;
    margin-top: 15px;
}
.spaceDiv{
    margin-bottom: 50px;
}
.cardServices{
    background: #101010;
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 26px;
    text-align: left;
}
.cardServices:hover{
    background: #101010;
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 26px;
    text-align: left;
    transform: translate(10px, -10px);
    box-shadow: -10px 10px 0px 0px #ffd450;
    transition: 600ms !important;
}
.fadeInUp {
    animation-name: fadeInUp;
}
.animated {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0,100%,0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

.servicesName{
    font-family: 'Bold';
    font-size: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 12px;
}
.servicesPoints{
    font-family: 'Medium';
    font-size: 18px;
    color: #d4d4d4;
    margin-bottom: 6px;
}
.spaceServiceRow{
    margin-bottom: 30px;
}
.fifthFold{
    padding: 120px 0px;
}
.sixthFold{
    padding: 120px 0px;
    background-color: #101010;
    background-image: url('../Assets/blackBg.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
.leftCard{
    background: #191E29;
    border-radius: 6px;
    padding: 50px 0px 50px 50px;
    margin-top: 13%;
}
.leftCardTxt{
    font-family: 'Bold';
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    font-size: 42px;
    color: #FFFFFF;
    text-align: left;
}
.yellowBig{
    color: #FFE948;
}
.rightCard{
    background: #3F81FF;
    border-radius: 6px;
    padding: 50px 35px 20px 35px;
    width: 110%;
    height: 403px;
    margin-left: -30px;
    text-align: left;
}
.quoteStyle{
    max-width: 100%;
    height: 65px;
    margin-bottom: 40px;
}
.clientName{
    font-family: 'SemiBold';
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 2px;
}
.ClientsDesignation{
    font-family: 'Medium';
    font-size: 16px;
    color: #002a79;
    margin-bottom: 12px;
}
.paraTestimonials{
    font-family: 'Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    height: 100px;
    line-height: 150%;
    color: #FFFFFF;
    margin-bottom: 65px;
}
.carousel-control-next, .carousel-control-prev {
    display: none;
}
.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}
.carousel-indicators .active{
    background-color: #FFE948;
}
.carousel-indicators {
    position: absolute;
    /* right: 0; */
    bottom: 0;
    left: 1 !important;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
}
.trustedByHead{
    font-family: 'Black';
    font-size: 30px;
    text-transform: uppercase;
    color: #FFFFFF;     
}
.subHeadTestimonials{
    font-family:'Medium';
    font-size: 20px;
    line-height: 160%;
    color: #ffffff;
    text-align: center;   
    margin-bottom: 10px;
}
.clientsLogo{
    margin-top: 120px;
}
.clients-slideshow-main {
    background-size: 1500px;
    background-repeat: repeat-x;
    width: 100%;
    height: 120px;
}
.clients-slideshow-main.slideright-r {
    background-image: url('../Assets/2.png');
}
.clients-slideshow-main.slideright-r {
    animation: slideright 400s infinite linear;
    -webkit-animation: slideright 400s infinite linear;
    background-image: url('../Assets/2.png');
}

@keyframes slideright {
    0%{
        background-position: 0px;
    }
    100%{
        background-position: -10000px;
    }
}
.clients-slideshow-main.slideleft-r {
    background-image: url('../Assets/home/leftLogo.png');
}
.clients-slideshow-main.slideleft-r {
    animation: slideleft 500s infinite linear;
    -webkit-animation: slideleft 500s infinite linear;
    background-image: url('../Assets/home/leftLogo.png');
}

@keyframes slideleft {
    0%{
        background-position: -10000px;
    }
    100%{
        background-position: 0px;
    }
}
.clients-slideshow-main.slidelefttop-r {
    background-image: url('../Assets/leftLogoTop.png');
}
.clients-slideshow-main.slidelefttop-r {
    animation: slidelefttop 500s infinite linear;
    -webkit-animation: slidelefttop 500s infinite linear;
    background-image: url('../Assets/leftLogoTop.png');
}

@keyframes slidelefttop {
    0%{
        background-position: -10000px;
    }
    100%{
        background-position: 0px;
    }
}
.emptySpace{
    margin-top: 40px;
}
.lastPara{
    font-family: 'Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 120%;
    color: #2b2b2b;
    text-align: left;
}
.taglineMain{
    font-family: 'SemiBold';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    color: #101010;
    text-align: left;
    margin-bottom: 15px;
}
.colorBlue{
    color: #3F81FF;
}
.ctaDiv{
    padding: 120px 0px 100px 0px;
}
.ctaImage{
    height: 420px;
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
    max-width: 100%;
}
@keyframes up-down {
    0% {
        transform: translate(0, 0px) rotate(
    0deg);
    }
    40% {
        transform: translate(0, 24px) rotate(
    0.8deg);
    }
    80% {
        transform: translate(0, -12px) rotate(
    -0.4deg);
    }
    100% {
        transform: translate(0, 0px) rotate(
    0deg);
    }
}
.spaceTopCta{
    margin-top: 80px;
}
.clouds {
    width: 100vw;
    height: 100vh;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
@media (min-width: 75em){
.cloud1 {
    width: 240px;
    height: 160px;
    top: 380px;
}
.cloud2 {
    width: 120px;
    height: 80px;
    top: 360px;
}
.cloud3 {
    width: 160px;
    height: 120px;
    top: 540px;
}
.cloud4 {
    width: 90px;
    height: 60px;
    top: 100px;
}
.bicycle-animation {
    -webkit-animation: bicycleani 13s infinite linear;
    -moz-animation: bicycleani 13s infinite linear;
    -o-animation: bicycleani 13s infinite linear;
    animation: bicycleani 13s infinite linear;
}
}
@media (min-width: 60em){
    .bicycle-animation {
        -webkit-animation: bicycleani 15s infinite linear;
        -moz-animation: bicycleani 15s infinite linear;
        -o-animation: bicycleani 15s infinite linear;
        animation: bicycleani 15s infinite linear;
    }
}
@media (min-width: 48em){
.cloud1 {
    width: 150px;
    height: 100px;
    top: 440px;
}
.cloud2 {
    width: 60px;
    height: 40px;
    top: 440px;
}
.cloud3 {
    width: 120px;
    height: 90px;
    top: 520px;
}
.cloud4 {
    width: 60px;
    height: 40px;
    top: 80px;
}
.bicycle-animation {
    -webkit-animation: bicycleani 14s infinite linear;
    -moz-animation: bicycleani 14s infinite linear;
    -o-animation: bicycleani 14s infinite linear;
    animation: bicycleani 14s infinite linear;
}
.bicycle, .bicycle img {
    width: 32.063em;
    height: 20.75em;
}
}
.bicycle-animation {
    -webkit-animation: bicycleani 15.6s infinite linear;
    -moz-animation: bicycleani 15.6s infinite linear;
    -o-animation: bicycleani 15.6s infinite linear;
    animation: bicycleani 15.6s infinite linear;
}
.bicycle, .bicycle img {
    width: 21.25em;
    height: auto;
}
@keyframes bicycleani {
    0% {
        transform: translate(-40vw, 0);
    }
    100% {
        transform: translate(120vw, 0);
    }
}
.cloud-ani1 {
    -webkit-animation: cloudani1 20s infinite linear;
    -moz-animation: cloudani1 20s infinite linear;
    -o-animation: cloudani1 20s infinite linear;
    animation: cloudani1 20s infinite linear;
}
.cloud-ani3 {
    -webkit-animation: cloudani1 35s infinite linear;
    -moz-animation: cloudani1 35s infinite linear;
    -o-animation: cloudani1 35s infinite linear;
    animation: cloudani1 35s infinite linear;
}
.cloud3 {
    width: 80px;
    height: 60px;
    top: 400px;
}
.cloud-ani2 {
    -webkit-animation: cloudani2 40s infinite linear;
    -moz-animation: cloudani2 40s infinite linear;
    -o-animation: cloudani2 40s infinite linear;
    animation: cloudani2 40s infinite linear;
}
.cloud-ani4 {
    -webkit-animation: cloudani4 50s infinite linear;
    -moz-animation: cloudani4 50s infinite linear;
    -o-animation: cloudani4 50s infinite linear;
    animation: cloudani4 50s infinite linear;
}
.cloud4 {
    width: 60px;
    height: 40px;
    top: 60px;
}
.cloud2 {
    width: 60px;
    height: 40px;
    top: 280px;
}

.cloud1 {
    width: 120px;
    height: 80px;
    top: 300px;
}
.cloud1, .cloud2, .cloud3, .cloud4 {
    position: absolute;
}
.superPowersTxt{
    font-family: 'ITC_AvantBook';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    color: #101010;
}
.center{
    text-align: center !important;
}
@keyframes cloudani1 {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(-100vw);
    }
}
@keyframes cloudani2 {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(-100vw);
    }
}
@keyframes cloudani4 {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(-100vw);
    }
}
.footer{
    background-color: #101010;
    padding: 60px 0px 10px 0px;
}
.clients-slideshow-footer {
    background-size: 1500px;
    background-repeat: repeat-x;
    width: 100%;
    height: 209px;
} 
.clients-slideshow-footer.slideright-f {
    background-image: url('../Assets/paperlayer.svg');
}
.clients-slideshow-footer.slideright-f {
    animation: slideright 400s infinite linear;
    -webkit-animation: slideright 400s infinite linear;
    background-image: url('../Assets/paperlayer.svg');
}

@keyframes slideright {
    0%{
        background-position: 0px;
    }
    100%{
        background-position: -10000px;
    }
}
.lineFooter{
    border-top: 1px solid rgba(255, 255, 255, 0.445);
    padding: 26px 0px 3px 0px;
}
.taglineFooter{
    font-family: 'ITC_AvantMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #FFF01F;
    text-align: left;
    margin-bottom: 5px;
}
.footerLogoTxt{
    font-family: 'ITC_AvantMedium';
    font-style: normal;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07em;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 5px;
}
.footerLogoTxtRight{
    font-family: 'ITC_AvantMedium';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-top: 4px;
}
.superPowersBoard{
    background: #FAFAFA;
    border: 10px solid #101010;
    background-image: url('../Assets/bgSuperpowers.png');
    background-position: center;
    background-size: cover;
    height: auto;
    padding: 65px;
}
.fluidSuperpowers{
    max-width: 100%;
    height: 238px;
}
.indexUp{
    z-index: 11;
}
.superPowersLeft{
    margin-top: 61px;
}
.superPowersRight{
    margin-left: -110px;
    margin-top: 62px;
}
.spaceBottomFifth{
    margin-bottom: 50px;
}

/* Media Query for Mobile */
@media only screen and (max-width: 600px) {
    .mainHeadBig{
        font-size: 54px;
        line-height: 100% !important;
        letter-spacing: normal !important;
    }
    .MainHeadSmall{
        font-size: 16px;
    }
    .MainHeadLower{
        font-size: 16px;
    }
    .teamImageStyle{
        height: 180px;
        margin-left: 3%;
        max-width: 100%;
    }
    .avatarsImageBox{
        text-align: center;
    }
    .btnDark {
        padding: 18px 36px;
        font-size: 16px;
    }
    .paddingMainSecond{
        margin: 0px auto;
    }
    .cardServices{
        margin-bottom: 30px;
    }
    .superPowersBoard{
        padding: 35px 30px;
    }
    .superPowersLeft{
        display: none;
    }
    .superPowersRight{
        display: none;
    }
    .mViewSpace{
        margin-bottom: 20px;
    }
    .spaceBottomFifth{
        margin-bottom: 40px;
    }
    .leftCard {
        background: #191E29;
        border-radius: 6px;
        padding: 24px;
        /* margin-top: 9%; */
    }
    .leftCardTxt {
        font-family: 'Bold';
        font-style: normal;
        font-weight: normal;
        font-size:30px;
        color: #FFFFFF;
        text-align: left;
    }
    .rightCard{
        margin-left: auto;
        width: 100%;
        height: auto !important;
        padding: 40px 20px 20px 20px;
    }
    .paraTestimonials{
        height: auto !important;
    }
    /* .mainHead{
        font-size: 30px;
        text-transform: uppercase;
    } */
    .mainHeadLight, .mainHead{
        font-size: 30px;
        text-transform: uppercase;
    }
    .trustedByHead{
        font-size: 28px;
        text-transform: uppercase;
    }
    .ctaImage{
        height: auto;
    }
    .spaceTopCta{
        margin-top: auto;
    }
    .adjustAnimate{
        height: 150px !important;
    }
    .bicycle, .bicycle img{
        height: auto;
    }
    .footerLogoTxtRight{
        display: none;
    }
    .footerLogoTxt, .taglineFooter{
        text-align: center;
    }
    .styleWwd3{
        margin-top: auto;
    }
    .desktopViewShow{
        display: none !important;
    }
    .mobileViewShow{
        display: block !important;
    }
    .styleNavTab{
        font-size: 16px;
        border: 1px solid #00000096;
    }
    .spaceTopSecond{
        margin-top: 20px;
    }
    .taglineMain{
        text-align: center;
        text-transform: none;
        font-size: 28px;
        margin-bottom: 12px;
        margin-top: 35px;
    }
    .lastPara{
        font-size: 18px !important;
        text-align: center;
    }
    .ctaDiv, .thirdFold, .fourthFold, .fifthFold, .sixthFold{
        padding: 80px 0px;
    }
    .scroll-top {
        right: 22px!important;
        height: 50px!important;
        bottom: 29px;
        z-index: 12000;
        position: fixed;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    .styleImage, .styleWwd{
        height: auto;
    }
    .styleWwd{
        margin: 50px 0px;
    }
    .fluidSuperpowers{
        max-width: 100%;
        height: 240px;
    }
    .whiteBtn, .blackBtn {
        padding: 14px 30px;
        font-size: 14px;
    }
    .btnSpaceTop{
        margin-top: 50px;
    }
    .topBottom{
        padding: 80px 0px;
    }
    .mainBodyPara{
        line-height: 1.5;
    }
    .leftCard{
        margin-top: 5%;
    }
    .clientsLogo{
        margin-top: 80px;
    }
  }
  .scroll-top{
    right: 40px;
    height: 55px;
    bottom: 29px;
    z-index: 12000;
    position: fixed;
    background-color: rgba(0, 0, 0, 0) !important;
}
.scroll-top:hover{
  cursor: pointer;
}
.desktopViewShow{
    display: block;
}
.mobileViewShow{
    display: none ;
}


