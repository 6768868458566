.footer{
    background-color: #000;
    padding: 60px 0px 10px 0px;
}
.clients-slideshow-footer {
    background-size: 1500px;
    background-repeat: repeat-x;
    width: 100%;
    height: 209px;
} 
.clients-slideshow-footer.slideright-f {
    background-image: url('../Assets/paperlayer.svg');
}
.clients-slideshow-footer.slideright-f {
    animation: slideright 400s infinite linear;
    -webkit-animation: slideright 400s infinite linear;
    background-image: url('../Assets/paperlayer.svg');
}

@keyframes slideright {
    0%{
        background-position: 0px;
    }
    100%{
        background-position: -10000px;
    }
}
.lineFooter{
    border-top: 1px solid rgba(255, 255, 255, 0.445);
    padding: 26px 0px 3px 0px;
}
.taglineFooter{
    font-family: 'Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: #FFF01F;
    text-align: left;
    margin-bottom: 5px;
}
.footerLogoTxt{
    font-family: 'SemiBold';
    font-style: normal;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 5px;
}
.footerLogoTxtRight{
    font-family: 'Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-top: 4px;
}


.spaceBottomFifth{
    margin-bottom: 50px;
}
.footerCta{
    font-family: 'Medium';
    font-style: normal;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 130%;
    text-align: left;
    margin-bottom: 5px;
}
.footerLinksHead{
    font-family: 'SemiBold';
    font-size: 20px;
    color: #ffffff;
    text-align: left;
    margin-top: 10px;
    letter-spacing: 0.01em;
    margin-bottom: 15px;
    /* text-transform: uppercase; */
}
.fSpace{
    margin-bottom: 60px;
}
.linksFooter{
    font-family: 'Medium';
    font-style: normal;
    font-size: 16px;
    color: #e7e7e7;
    text-align: left;
    margin-bottom: 10px;
}
.linksFooter:hover{
    color: #FFE948;
    transition: .5s all ease-in;
    opacity: 1;
}

.outlineBtnF{
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 18px 36px;
    font-family: 'SemiBold';
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    transition: color 0.20s ease-in-out, background-color 0.20s ease-in-out, border-color 0.20s ease-in-out, box-shadow 0.25s ease-in-out;
}
.outlineBtnF:hover{
    animation: trianglegf 1.5s linear infinite;
        background: linear-gradient(
            135deg, #17B384 0.25em, transparent 0.25em) -0.5em 0, linear-gradient(
            225deg, #147055 0.25em, transparent 0.25em) -0.5em 0, linear-gradient(
            315deg, #018f64 0.25em, transparent 0.25em) 0 0, linear-gradient(
            45deg, #09c28a 0.25em, transparent 0.25em) 0 0;
        background-size: 0.75em 0.6em;
        color: white !important;
        background-color: transparent !important;
        border-color: #17B384 !important;
}
.arrowSpacingF{
    height: 18px;
    margin-left: 12px;
}

@keyframes trianglegf {
    100% {
        background-position: 4em 0, 2em 0, -0.9em 0 , -0.75em 0;
    }
}
.alignLeftFooter{
    text-align: left;
    margin-top: 32px;
}
img.socialIconStyle {
    height: 32px;
    max-width: 100%;
    margin-right: 26px;
}
.alignLeftF{
    text-align: left;
}
.socialIconStyle{
    transition: all .3s ease-in-out;
}
.socialIconStyle:hover{
    transform: scale(1.2);
}
/* Media Query for Mobile */
@media only screen and (max-width: 600px) {
    .footerLogoTxtRight{
        display: none;
    }
    .footerLogoTxt{
        margin-top: 10px !important;
    }
    .footerLogoTxt, .taglineFooter{
        text-align: left;
    }
    .alignLeftFooter{
        margin-bottom: 40px !important;
    }
    .lineFooter{
        padding: 8px 0px 12px 0px;
    }
  }