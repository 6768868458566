.comingSoonMain{
    padding: 30px 0px 0px 0px;
}
.spaceComing{
    margin-top: 180px;
}
.webPageHead{
    font-family: 'Black';
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    text-transform: uppercase;
    color: #101010;
    text-align: left;
}
.colorChangeCS{
    color: #3F81FF;
    font-family: 'Black';
}
.paraTxtCS{
    font-family: 'Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: #101010;
    text-align: left; 
    margin-top: 10px;
}
.heightAdjustCA{
    height: 600px;
    max-width: 100%;
}
.spaceBtn{
    margin-top: 40px;
    text-align: left;
}
.whiteBtn{
    padding: 12px 30px;
    font-family: 'Bold';
    font-size: 14px;
    color: #101010;
    text-align: center; 
    border: 2px solid #101010;
    border-radius: 6px;
}
.whiteBtn:hover{
    background-color: #101010;
    font-family: 'Bold';
    font-size: 14px;
    color: #fff;
    transition: ease-in .3s all;
    text-align: center; 
    border: 2px solid #101010;
    border-radius: 6px;
}
.mobileShow{
    display: none !important; 
}
.desktopShow{
    display: block !important; 
}
@media only screen and (max-width: 600px) {
    .webPageHead{
        font-size: 32px;
        text-align: center;
    }
    .mobileShow{
        display: block !important;
    }
    .desktopShow{
        display: none !important; 
    }
    .heightAdjustCA{
        height: auto;
        max-width: 100%;
    }
    .spaceComing{
        margin-top: 0px;
    }
    .comingSoonMain{
        padding: 40px 0px 80px 0px;
    }
    .paraTxtCS{
        text-align: center;
    }
    .spaceBtn{
        text-align: center;
    }
    .whiteBtn {
        padding: 14px 32px;
        font-size: 14px;
    }
}