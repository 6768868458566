.divMain{
    padding: 170px 0px 100px 0px;
    background-color: #000;
}


.startMainHead{
    font-family:'Black';
    font-size: 52px;
    line-height: 52px;
    color: #FFDC09;
    text-align: left;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.pageSubPara{
    font-family: 'SemiBold';
    color: #fff;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    margin: 0px;
}
.styleCube{
    margin-top: -50px !important;;
}
.serviceCardUp{
    padding: 22px;
    border-radius: 6px;
    border: 1px solid #fff;
    background-color: #101010;
    height: 276px;
    text-align: left;
    /* transform: translate(10px, -10px); */
    /* box-shadow: -10px 10px 0px 0px #101010;
    transition: 600ms !important; */
}
.styleImgSc{
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
}
.cardHeadS{
    font-family:'Bold';
    color: #fff;
    text-align: left;
    font-size: 30px;
    margin-bottom: 4px;
    margin-top: 0px;
}
.paraSer{
    font-family:'Medium';
    color: #f6f6f6;
    text-align: left;
    font-size: 18px;
    margin: 0px;
}
.serviceCardUp:hover{
    transform: translate(10px, -10px);
    box-shadow: -10px 10px 0px 0px #FFDC09;
    transition: 600ms !important;
}

.cardServices{
    background: #101010;
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 26px;
    text-align: left;
}

.serviceTopSpace{
    margin-top: 60px;
}
.bgWhiteService{
    padding: 100px 0px;
}
.bgOffService{
    padding: 100px 0px;
    background-color: #fafafa;
}
.box{
    height: 40px;
    width: 40px;
    background-color: black;
}

.servicePara{
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #353535;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 22px;
}
.serviceHead{
    font-family: 'Black';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: .02em;
    text-transform: uppercase;
    line-height: 120%;
    color: #101010;
    text-align: left;
    margin-bottom: 12px;
}
.s-40{
    padding-bottom: 80px;
}
.uxDesignAnimate{
    margin-top: -20px !important;
}  
.brandingAnimate{
    margin-top: -80px !important;
} 
.servicePonits{
    text-align: left;
    font-family: 'Medium';
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    color: #101010;
    margin-top: 0px;
    margin-bottom: 12px;
}
.txtService{
    max-width: 100%;
    height: auto;
}
@media only screen and (max-width: 600px) {
    .serviceHead{
        font-size: 30px !important;
    }
    .divMain {
        padding: 160px 0px 100px 0px;
        background-color: #101010;
    }
    .startMainHead {
        font-family: 'Black';
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 10px;
        letter-spacing: .07em;
    }
    .pageSubPara {
        font-family: 'Medium';
        color: #fff;
        text-align: left;
        font-size: 18px;
        line-height: 160%;
    }
    .mSpaceC{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .s-40{
        padding-bottom: 26px;
    }
    .mViewUx{
        margin-top: -40px !important;
        margin-left: -70px !important;
    }
    .styleMInfo{
        margin-top: -20px;
    }
    .bgWhiteService, .bgOffService {
        padding: 80px 0px;
    }
    .mStyleUx{
        margin-top: 10px !important;
        margin-bottom: 60px !important;
    }
    .brandSpaceM{
        margin-top: -20px;
    }
    .devMview{
        margin-top: 0px !important;
        margin-bottom: 20px !important;
    }
    .markMview{
        margin-top: 10px !important;
        margin-bottom: 60px !important;
    }
}