.btn_tabs{
    background-color: white !important;
    color: #000 !important;
    border: 1px solid #fff;
    font-family: "Bold";
    border-radius: 6px;
    text-transform: uppercase;
    margin-right: 30px;
}
.btn_tabs:hover{
    background-color: #FFDC09 !important;
    color: #000 !important;
    border: 1px solid #FFDC09;
    transition: .4s all ease-in-out ;
}
button.btn_tabs:active{
    background-color: #FFDC09 !important;
}
.tabs_space{
    margin-bottom: 70px !important;
    text-align: left;
}