
.menu {
    justify-content: center;
    margin: 0 auto;
    padding-left: 0;
}
.menu__item {
    display: inline-block;
    white-space: nowrap;
    margin-top: 18px;
}

.menu__item:last-child .menu__link {
    border-right: 0;
}
.menu__link {
    display: block;
    padding: 0px 20px 6px;
    font-family: 'Semibold';
    font-style: normal;
    font-size: 18px;
}
.activeState{
    color: #2c6ee7 !important;
}
.menu__link:hover .menu__first-word, .menu__link:focus .menu__first-word {
    transform: translate3d(0, -105%, 0);
}
.menu__link:hover .menu__second-word, .menu__link:focus .menu__second-word {
    transform: translate3d(0, 105%, 0);
}

.menu__title {
    display: inline-block;
    overflow: hidden;
}
.menu__first-word, .menu__second-word {
    display: inline-block;
    position: relative;
    transition: transform 0.3s;
}
.menu__first-word::before, .menu__second-word::before {
    position: absolute;
    content: attr(data-hover);
}
.menu__first-word {
    color: #101010;
}
.menu__first-word::before {
    top: 105%;
    color: #2c6ee7;
}

.autoAlign{
    margin-left: auto;
}
.lineAnimate{
    border-bottom: none;
    background-image: url('../Assets/line.svg');
    background-position: 0 100%;
    background-size: auto 6px;
    background-repeat: repeat-x;
    text-decoration: none;
    height: 8px;
    margin-top: -12px;
}
.bg-light{
    padding: 10px 10px;
}
.navbar-light .navbar-toggler-icon{
    background-image: url('../Assets/menu.svg') !important;
}
.navbar-light .navbar-toggler {
    color: rgb(0 0 0 / 0%);
    border-color: rgb(0 0 0 / 0%);
}
.navbar-toggler {
    border:none !important;
}

.styleLogo{
    height: 40px;
}
@media only screen and (max-width: 600px) {
    .menu {
        justify-content: center;
        margin: -70px auto 0px auto !important;
        padding-left: 0;
        height: 100vh;
        justify-content: center;
        margin: 0 auto;
        align-items: center;
        padding-left: 0;
        display: flex;
        flex-direction: column;
    }
.navbar-nav{
    height: 100vh;
}
    .styleLogo{
        height: 32px !important; 
    }
    .navbar-light .navbar-toggler-icon {
        height: 20px !important;
    }
    .menu__item{
        list-style-type: none;
    }
    .menuHead{
        display: block !important;
    }
    .menu__link{
        font-size: 26px !important;
        padding: 0px 20px 0px !important;
    }
    .fixed-top{
        z-index: 120001 !important;
    }
    .bg-light{
        padding: 15px 10px !important;
    }
    .navbar-brand{
        margin-left: 15px;
    }
    div#navbarScroll{
        background-image: url('../Assets/grid.svg');
        background-position: center;
        background-size: cover;
    }
}
.menuHead{
    display: none;
}
