.blockworkpg{
    padding: 200px 0px 62px 0px;
}
.herohead_txt{
    font-family: 'ExtraBold';
    font-size: 42px;
    text-transform: uppercase;
    line-height: 136%;
    color: #101828;
    padding-right: 15%;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 50px;
}

.mountainBg{
    background-image: url(../Assets/Ornament.svg);
    background-color: #000;
    background-size: cover;
    background-position: center;
    height: 95vh;
    padding-top: 100px;
    overflow: hidden !important;
}
body{
    overflow: hidden !important; 
}
.white_color{
    color: #FFDC09;
}
.spaceTopWork{
    padding-top: 12%;
    padding-bottom: 2%;
}
.workHead{
    font-family: 'Bold';
    font-size: 36px;
    line-height: 42px;
    color: #ffffff;
    text-align: left;
    margin-bottom: 2px;
    text-transform: none;
}
.highlight_light{
    font-family: 'Bold';
    font-size: 36px;
    line-height: 42px;
    color: #ffffff;
    text-align: left;
    margin-bottom: 20px;
    text-transform: none;

}
.workPara{
    font-family: 'SemiBold';
    color: #efefef;
    text-align: left;
    font-size: 20px;
    line-height: 140%;
    margin: 0px;
}
.style_cubes{
    position: absolute;
    top: 0px;
    right: 12%;
}
.styleCube_Height{
 margin-top: -44px !important;
 height: 300px;
}
img.gridUpperNeon{
    max-width: 100%;
    height: auto;
}
/* marquee div container */
.marquee {
    font-family:'ExtraBold';
    font-size: 30px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    color: #000;
    padding-top: 17px;
    height: 68px;
    overflow: hidden;
    background-color: #FFDC09;
    position: relative;
}
.starStyle{
    margin: -18px 28px 0px 28px;
    height: auto;
    max-width: 100%;
}
/* nested div inside the container */
.marquee div {
    /* display: block; */
    width: 200%;
    position: absolute; 
    overflow: hidden;
    animation: marquee 20s linear infinite;
}
.leftD{
    display: flex;
}

/* keyframe */
@keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
}
.gridBg{
    background-image: url('../Assets/work/gridbg.svg');
    background-color: #000;
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    padding: 100px 0px;
    /* height: 100vh; */
}
/* Vector 9 */


@media only screen and (max-width: 600px) {
    .mountainBg{
        padding-top: 140px;
    }
 .workHead{
    font-family:'Bold';
    font-size: 30px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 14px;
 }
 .workPara{
    font-family:'Medium';
    font-size: 18px;
    line-height: 140%;
    color: #efefef;
}
.marquee div{
    width: 800%;
    animation: marquee 10s linear infinite;
}
.marquee {
    font-family:'ExtraBold';
    font-size: 24px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    color: #000;
    padding-top: 16px;
    height: 62px;
    overflow: hidden;
    background-color: #FFDC09;
    position: relative;
}
.style_cubes{
    position: relative !important;
    margin-left: -100px;
}
.styleCube_Height{
    height: 200px;
    max-width: 100%;
}
}
