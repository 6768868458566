.project_div{
    margin-bottom: 62px;
}

.project_title{
    font-family:'Bold';
    font-size: 28px;
    line-height: 120%;
    color: #050411;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 36px;
    /* text-transform: uppercase; */
}
.style_custom{
    /* background-color: #101010c7 !important; */
    border: none !important;
    border-radius: 6px;
    padding: 0px 20px;
}
.project_mini_descp{
    font-family:'Medium';
    font-size: 20px;
    line-height: 140%;
    color: #050411;
    text-align: left;
    margin-bottom: 12px;
}
.project_mini_descp_bel{
    color: #637695;
    font-family: 'Medium';
    font-size: 18px;
    text-align: left;
    margin-top: 0px;
    line-height: 130%; /* 23.4px */
}
/* .project_btn{
    padding: 10px 36px;
    font-family:'ExtraBold';
    font-size: 16px;
    line-height: 140%;
    color: #000;
    margin-right: auto;
    margin-bottom: -30px;
    border: 1px solid #FFDC09;
} */
.project_btn:hover{
    background-color: #000;
    color: #FFDC09;
    transition: .3s all ease-in-out;
    border: 1px solid #FFDC09;
}
.project_img{
    max-width: 100% !important;
    height: auto !important;
}
.cube_animate{
    height: 200px;
    width: 200px;
    background-color: #FFDC09;
    position: sticky;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    /* height: fit-content; */
    top: 10%;
    bottom: auto;
    z-index: 9999999;
    right: 0px;
}